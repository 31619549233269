export const APP_STORE_LINK =
  'https://apps.apple.com/us/app/atm-instant-cash-rewards/id1507829573'
export const GOOGLE_PLAY_LINK =
  'https://play.google.com/store/apps/details?id=com.atm.atm.prod'
export const CUSTOMER_SERVICE_LINK =
  'https://atm.atlassian.net/servicedesk/customer/portals'
export const FEATURES_LINKS = [
  {
    name: 'Budgeting',
    description: 'Budget Smarter',
    href: '/#budgeting',
  },
  {
    name: 'Cash Advance',
    description: 'Get Cash When You Need It',
    href: '/#cash-advance',
  },
  {
    name: 'Personal Finance',
    description: 'Track Your Spending',
    href: '/#personal-finance',
  },
  {
    name: 'Rewards & Cashback',
    description: 'Earn Money',
    href: '/#rewards',
  },
]
