import clsx from 'clsx'
import { Header } from '@ui/layout/Header'
import { Main } from '@ui/layout/Main'
import { Footer } from '@ui/layout/Footer'

export function Layout({
  children,
  layoutClassName,
  headerClassName,
  mainClassName,
  footerClassName,
  isLoggedIn = false,
  trackEvent,
}: {
  children: React.ReactNode
  layoutClassName?: string
  headerClassName?: string
  mainClassName?: string
  footerClassName?: string
  isLoggedIn?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  trackEvent?: (event: string, data?: Record<string, any>) => void
}) {
  return (
    <div className={clsx('flex min-h-screen flex-col', layoutClassName)}>
      <Header
        className={headerClassName}
        isLoggedIn={isLoggedIn}
        trackEvent={trackEvent}
      />
      <Main className={clsx('flex-grow', mainClassName)}>{children}</Main>
      <Footer className={footerClassName} isLoggedIn={isLoggedIn} />
    </div>
  )
}
