import Link from 'next/link'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { Button } from '@ui/components/buttons/Button'
import { FEATURES_LINKS } from '@ui/constants'

function DesktopNavLink({
  href,
  children,
}: {
  href: string
  children: React.ReactNode
}) {
  return (
    <Link
      key={href}
      href={href}
      className="hover:text-blueberry-700 font-medium"
    >
      <span className="relative z-10">{children}</span>
    </Link>
  )
}

function FeaturesDropdown() {
  return (
    <Menu as="div" className="relative">
      <MenuButton className="hover:text-blueberry-700 group flex w-full items-center justify-between font-medium outline-none">
        Features
        <ChevronDownIcon
          aria-hidden="true"
          className="ml-1 size-5 flex-none transition-transform group-data-[open]:rotate-180"
        />
      </MenuButton>
      <MenuItems
        transition
        className="absolute left-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
      >
        <div className="py-1">
          {FEATURES_LINKS.map((item) => (
            <MenuItem
              as={Link}
              key={item.name}
              href={item.href}
              className="data-[focus]:text-blueberry-700 data-[focus]:bg-blueberry-100 block px-4 py-2 text-sm font-medium text-gray-900"
            >
              {item.name}
            </MenuItem>
          ))}
        </div>
      </MenuItems>
    </Menu>
  )
}

export function DesktopNavLinks({
  className = 'flex items-center gap-8',
  isLoggedIn = false,
}: {
  className?: string
  isLoggedIn?: boolean
}) {
  return (
    <div className={className}>
      <div className="flex gap-8">
        <DesktopNavLink href="/about">About</DesktopNavLink>
        <FeaturesDropdown />
        <DesktopNavLink href="/press">Press</DesktopNavLink>
      </div>
      <div className="flex gap-4">
        <Button href="/sign-up" variant="whiteSm" className="min-w-[152px]">
          Sign Up
        </Button>
        <Button
          href="/download/"
          variant="blueberrySm"
          className="min-w-[152px]"
        >
          Download
        </Button>
      </div>
    </div>
  )
}
