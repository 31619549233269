import { Layout } from '@ui/layout/Layout'

export function WebsiteLayout({
  layoutClassName,
  headerClassName,
  mainClassName,
  footerClassName,
  children,
}: {
  layoutClassName?: string
  headerClassName?: string
  mainClassName?: string
  footerClassName?: string
  children: React.ReactNode
}) {
  return (
    <Layout
      layoutClassName={layoutClassName}
      headerClassName={headerClassName}
      mainClassName={mainClassName}
      footerClassName={footerClassName}
    >
      {children}
    </Layout>
  )
}
