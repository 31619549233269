import React from 'react'

export const LinkButton: React.FC<{
  className?: string
  onClick: () => void
  children: React.ReactNode
}> = ({ className, onClick, children }) => (
  <button
    className={
      className ||
      'text-blueberry-700 hover:text-blueberry-900 cursor-pointer border-none bg-transparent text-sm font-medium hover:underline'
    }
    onClick={onClick}
  >
    {children}
  </button>
)
