import React from 'react'
import Link from 'next/link'
import clsx from 'clsx'

type ReactClassNames = string

const classNamesSm: ReactClassNames = 'px-8 py-3 text-sm font-semibold'
const classNamesMd: ReactClassNames = 'px-10 py-3 text-sm font-bold'
const classNamesLg: ReactClassNames = 'px-14 py-5 text-base font-medium'
const classNamesWhite: ReactClassNames =
  'text-blueberry-700 text-center border border-blueberry-700 bg-white hover:text-blueberry-800 hover:bg-blueberry-100 active:bg-blueberry-200 active:text-blueberry-700 rounded-full leading-6 transition duration-150 ease-in-out whitespace-nowrap'
const classNamesBlueberry: ReactClassNames =
  'text-white text-center bg-blueberry-700 hover:text-white hover:bg-blueberry-800 active:bg-blueberry-500 active:text-white rounded-full leading-6 transition duration-150 ease-in-out whitespace-nowrap'

export const classNamesWhiteSm: ReactClassNames = clsx(
  classNamesWhite,
  classNamesSm,
)

export const classNamesWhiteMd: ReactClassNames = clsx(
  classNamesWhite,
  classNamesMd,
)

export const classNamesWhiteLg: ReactClassNames = clsx(
  classNamesWhite,
  classNamesLg,
)

export const classNamesBlueberrySm: ReactClassNames = clsx(
  classNamesBlueberry,
  classNamesSm,
)

export const classNamesBlueberryMd: ReactClassNames = clsx(
  classNamesBlueberry,
  classNamesMd,
)

export const classNamesBlueberryLg: ReactClassNames = clsx(
  classNamesBlueberry,
  classNamesLg,
)

const variantStyles = {
  blueberry: classNamesBlueberryMd,
  white: classNamesWhiteMd,
  blueberrySm: classNamesBlueberrySm,
  whiteSm: classNamesWhiteSm,
  blueberryMd: classNamesBlueberryMd,
  whiteMd: classNamesWhiteMd,
  blueberryLg: classNamesBlueberryLg,
  whiteLg: classNamesWhiteLg,
}

export type VariantKey = keyof typeof variantStyles

export type ButtonProps<Variant extends VariantKey> = {
  variant?: Variant
} & (
  | React.ComponentPropsWithoutRef<typeof Link>
  | (React.ComponentPropsWithoutRef<'button'> & {
      href?: undefined
    })
)

export const Button = React.forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  ButtonProps<VariantKey>
>(
  (
    { variant = 'blueberry', className, ...props }: ButtonProps<VariantKey>,
    ref,
  ) => {
    className = clsx(variantStyles[variant], className)

    return typeof props.href === 'undefined' ? (
      <button
        ref={ref as React.Ref<HTMLButtonElement>}
        className={className}
        {...props}
      />
    ) : (
      <Link
        {...props}
        ref={ref as React.Ref<HTMLAnchorElement>}
        href={props.href}
        className={className}
      >
        {props.children}
      </Link>
    )
  },
)

Button.displayName = 'Button'
