import Link from 'next/link'
import {
  LinkedInIcon,
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
} from '@ui/components/Svg'

type SocialIconsProps = {
  style?: object
  className?: string
}

export function SocialIcons({ style, className }: SocialIconsProps) {
  return (
    <div className={`social-icons ${className}`} style={{ ...style }}>
      <Link
        href="https://www.linkedin.com/company/atmdotcom/"
        rel="noopener noreferrer"
        target="_blank"
        className="hover:text-blueberry-200 transition-colors"
      >
        <LinkedInIcon className="h-8 w-8" fill="currentColor" />
      </Link>
      <Link
        href="https://www.facebook.com/atmapp-105223718404977/"
        rel="noopener noreferrer"
        target="_blank"
        className="hover:text-blueberry-200 transition-colors"
      >
        <FacebookIcon className="h-8 w-8" fill="currentColor" />
      </Link>
      <Link
        href="https://www.instagram.com/atmapp"
        rel="noopener noreferrer"
        target="_blank"
        className="hover:text-blueberry-200 transition-colors"
      >
        <InstagramIcon className="h-8 w-8" fill="currentColor" />
      </Link>
      <Link
        href="https://twitter.com/atmdotcom"
        rel="noopener noreferrer"
        target="_blank"
        className="hover:text-blueberry-200 transition-colors"
      >
        <TwitterIcon className="h-8 w-8" fill="currentColor" />
      </Link>
    </div>
  )
}
