import React from 'react'
import clsx from 'clsx'
import { Button, VariantKey } from '@ui/components/buttons/Button'

type SubmitButtonProps = {
  variant?: VariantKey
} & Omit<React.ComponentPropsWithoutRef<'button'>, 'color' | 'variant'>

export function SubmitButton({
  children,
  className,
  ...props
}: SubmitButtonProps) {
  return (
    <Button
      type="submit"
      variant="blueberry"
      className={clsx(
        'mt-4 flex w-full items-center justify-center',
        className,
      )}
      {...props}
    >
      {children}
    </Button>
  )
}
